import account from "~/i18n/fr-CH/account.json";
import form from "~/i18n/fr-CH/form.json";
import changePassword from "~/i18n/fr-CH/changePassword.json";
import recoveryPassword from "~/i18n/fr-CH/recoveryPassword.json";
import checkout from "~/i18n/fr-CH/checkout.json";
import general from "~/i18n/fr-CH/general.json";
import cart from "~/i18n/fr-CH/cart.json";
import listing from "~/i18n/fr-CH/listing.json";
import product from "~/i18n/fr-CH/product.json";
import newsletter from "~/i18n/fr-CH/newsletter.json";
import validations from "~/i18n/fr-CH/validations.json";
import errors from "~/i18n/fr-CH/errors.json";
import go2flow from "~/i18n/fr-CH/go2flow.json";

export default {
  ...account,
  ...form,
  ...changePassword,
  ...recoveryPassword,
  ...checkout,
  ...general,
  ...cart,
  ...listing,
  ...product,
  ...newsletter,
  ...validations,
  ...errors,
  ...go2flow
};
