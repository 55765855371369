<script setup lang="ts">
import { getCategoryRoute, getTranslatedProperty } from "@shopware/helpers";
import type { Schemas } from "#shopware";
import { onKeyStroke, useActiveElement } from "@vueuse/core";
type NavigationElement = Schemas["Category"] & {
  activeClass?: boolean;
};

const navigationElements = inject("swNavigation-service-navigation");
const currentMenuPosition = ref<string | undefined>(undefined);
const resetActiveClass = ref<boolean>(true);
const activeSubmenu = ref<string | null>(null);

const emit = defineEmits<{
  'close-menu': []
}>();

const props = defineProps<{
  open?: boolean;
}>();

const route = useRoute();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

onMounted(() => {
  let currentNaviagtionElement: NavigationElement | undefined;
  if (navigationElements.value) {
    currentNaviagtionElement = findNavigationElement(
      route.path.slice(1),
      navigationElements.value
    );
  }
  if (currentNaviagtionElement) {
    updateActiveClass(currentNaviagtionElement.id, currentNaviagtionElement.parentId);
  }
});

const findNavigationElement = (
  routePath: string,
  navigation: NavigationElement[]
): NavigationElement | undefined => {
  for (let i = 0; i < navigation.length; i++) {
    const navigationElement = navigation[i];
    const seoUrls = navigationElement.seoUrls as Schemas["SeoUrl"][] | undefined;
    if (seoUrls) {
      for (let j = 0; j < seoUrls.length; j++) {
        const currentSeoUrl = seoUrls[j];
        if (currentSeoUrl && currentSeoUrl.seoPathInfo === routePath) {
          return navigationElement;
        }
      }
    }
    const children = navigationElement.children;
    if (children) {
      const foundElement = findNavigationElement(routePath, children);
      if (foundElement) {
        return foundElement;
      }
    }
  }
  return undefined;
};

const onUpdateActiveClass = (navigationId: string, parentId: string | undefined) => {
  updateActiveClass(navigationId, parentId);
  emit('close-menu');
};

const resetNavigationActiveClass = (navigation: NavigationElement[]) => {
  for (let ni = 0; ni < navigation.length; ++ni) {
    navigation[ni].activeClass = false;
    const children = navigation[ni].children;
    if (children) {
      resetNavigationActiveClass(children);
    }
  }
};

const updateActiveClass = (navigationId: string, parentId: string | undefined) => {
  const setNavigationActiveClass = (
    navigation: NavigationElement[],
    navigationId: string,
    parentId: string | undefined
  ) => {
    for (let ni = 0; ni < navigation.length; ++ni) {
      if (navigation[ni].id === navigationId) {
        navigation[ni].activeClass = true;
      }
      if (navigation[ni].id == parentId) {
        navigation[ni].activeClass = true;
        if (navigationElements.value) {
          setNavigationActiveClass(
            navigationElements.value,
            navigationId,
            navigation[ni].parentId
          );
        }
      }
      const children = navigation[ni].children;
      if (children) {
        setNavigationActiveClass(children, navigationId, parentId);
      }
    }
  };

  if (navigationElements.value) {
    resetNavigationActiveClass(navigationElements.value);
    setNavigationActiveClass(navigationElements.value, navigationId, parentId);
    resetActiveClass.value = false;
  }
};
//#region - keyboard navigation (highliy connected to the HTML structure)
const activeElement = useActiveElement();
onKeyStroke("ArrowDown", (e) => {
  // next parent link or next child link
  if (
    activeElement.value?.parentElement?.nextElementSibling?.getElementsByTagName("a")[0]
  ) {
    e.preventDefault();
    activeElement.value.parentElement.nextElementSibling
      .getElementsByTagName("a")[0]
      .focus();
    return;
  }
});

onKeyStroke("ArrowUp", (e) => {
  // previous parent link or previous child link
  if (
    activeElement.value?.parentElement?.previousElementSibling?.getElementsByTagName(
      "a"
    )[0]
  ) {
    e.preventDefault();
    activeElement.value?.parentElement.previousElementSibling
      .getElementsByTagName("a")[0]
      .focus();
    return;
  }
});

//#endregion - keyboard navigation

// reset when route.path changes
watch(
  () => route.path,
  () => {
    if (resetActiveClass.value == true && navigationElements.value) {
      resetNavigationActiveClass(navigationElements.value);
    }
    resetActiveClass.value = true;
    currentMenuPosition.value = undefined;
    emit('close-menu');
  }
);

const beforeEnter = (el: any) => {
  el.style.height = "0";
};
const enter = (el: any) => {
  el.style.height = el.scrollHeight + "px";
};
const beforeLeave = (el: any) => {
  el.style.height = el.scrollHeight + "px";
};
const leave = (el: any) => {
  el.style.height = "0";
};

const toggleSubmenu = (navigationId: string) => {
  // If clicking the same menu, close it
  if (activeSubmenu.value === navigationId) {
    activeSubmenu.value = null;
  } else {
    // Close previous menu and open the new one
    activeSubmenu.value = navigationId;
  }
};
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <ul
    :class="{ '!block': open }"
    class="nav-menu2 flex flex-wrap text-skin-secondary desktop:px-2.5 prose-li:w-full prose-li:md:w-auto [&>li>a]:block [&>li>a]:px-5 desktop:[&>li>a]:px-2.5 desktop:border-r border-skin-primary text-sm desktop:[&>li>.menu:before]:content-[''] [&>li>.menu:before]:absolute [&>li>.menu:before]:w-full [&>li>.menu:before]:left-0 [&>li>.menu:before]:h-9 [&>li>.menu:before]:-top-9 min-w-[15rem] desktop:min-w-auto laptop:absolute top-full right-4 bg-skin-white flex-col [&>li>a]:py-4 desktop:static desktop:flex-row desktop:[&>li>a]:py-0 desktop:items-center [&>li>a]:max-w-[90%] desktop:[&>li>a]:max-w-none laptop:hidden desktop:!flex"
  >
    <template
      v-for="(navigationElement, index) in navigationElements"
      :key="navigationElement.id"
      @mouseover="currentMenuPosition = navigationElement.id"
      @focusin="currentMenuPosition = navigationElement.id"
    >
      <li
        class="group relative [&:hover>.menu]:opacity-100 [&:hover>.menu]:pointer-events-auto [&:hover>a]:text-skin-theme"
        :class="{
          'active': (navigationElement as NavigationElement).activeClass,
        }"
      >
        <span
          @click="toggleSubmenu(navigationElement.id)"
          v-show="navigationElement.children?.length > 0"
          class="toggle_sub-menu inline-block w-12 h-12 bg-dropdown bg-no-repeat bg-[center] desktop:hidden absolute right-3 top-0"
          :class="{ 'rotate-180': activeSubmenu === navigationElement.id }"
        ></span>
        <NuxtLink
          role="menuitem"
          :target="
            navigationElement.externalLink || navigationElement.linkNewTab ? '_blank' : ''
          "
          :to="formatLink(getCategoryRoute(navigationElement))"
          class="[&:before]:content-[attr(title)] [&:before]:h-0 [&:before]:overflow-hidden [&:before]:invisible [&:before]:block pointer-events-none"
          @click="updateActiveClass(navigationElement.id, navigationElement.parentId)"
        >
          {{ getTranslatedProperty(navigationElement, "name") }}
        </NuxtLink>
        <Transition
          name="submenu"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <ul
            v-show="activeSubmenu === navigationElement.id"
            class="menu sub-menu min-w-[14rem] bg-skin-white desktop:absolute top-[3.25rem] left-0 prose-a:block prose-a:p-4 [&>li:hover>a]:text-skin-theme desktop:opacity-0 desktop:transition-all desktop:duration-300 desktop:pointer-events-none desktop:!block pl-4 md:pl-0"
            style="z-index: 1000;"
            @click="currentMenuPosition = navigationElement.id"
            @mouseleave="currentMenuPosition = undefined"
          >
            <template v-if="navigationElement.children?.length > 0">
              <LayoutTopNavigationRecursive
                :navigation-element-children="navigationElement.children"
                row="1"
                @update-active-class="onUpdateActiveClass"
                @focusout-last-element="currentMenuPosition = undefined"
              />
            </template>
          </ul>
        </Transition>
      </li>
    </template>
  </ul>
</template>

<style scoped>
@media (max-width: 1359px) {
  .toggle-menu {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.5s ease-out;
  }
  .toggle-menu.open {
    grid-template-rows: 1fr;
  }
}

/* Add these transition styles */
.submenu-enter-active,
.submenu-leave-active {
  transition: all 0.3s ease-out;
  overflow: hidden;
}

.submenu-enter-from,
.submenu-leave-to {
  height: 0;
  opacity: 0;
}

.toggle_sub-menu {
  transition: transform 0.3s ease;
}
</style>
