<script setup lang="ts">
import {
  useNavigationSearch,
  useInternationalization,
  useSessionContext,
} from "@shopware/composables";

const props = defineProps<{
  type?: any;
}>();

const type = props.type;
const route = useRoute(); // Current route information
const { languages, changeLanguage } = useInternationalization(); // Internationalization helpers
const { languageIdChain } = useSessionContext(); // Current session context
const { resolvePath } = useNavigationSearch();
// Handle language change
const onChangeHandler = async (event: Event) => {
  try {
    const selectedLanguageId = (event.target as HTMLSelectElement).value;
    const currentFullPath = route.fullPath.replace(/^\/[a-z]{2}-[A-Z]{2}/, "");
    if (!currentFullPath) {
      const data = await changeLanguage(selectedLanguageId);
      window.location.href = data.redirectUrl;
    } else {
      const currentSeoResult = await resolvePath(currentFullPath);
      const currentPathInfo = currentSeoResult?.pathInfo;
      const data = await changeLanguage(selectedLanguageId);
      if (data.redirectUrl) {
        if(currentPathInfo) {
          const changedSeoResult = await resolvePath(currentPathInfo);
          const seoPath = changedSeoResult?.seoPathInfo;
          if(seoPath) {
            window.location.href = data.redirectUrl + '/' + seoPath;
          } else{
            window.location.href = data.redirectUrl + currentPathInfo;
          }
        } else{
          window.location.href = data.redirectUrl + currentFullPath;
        }
      } else {
        window.location.reload();
      }
    }
  } catch (error) {
    console.error("Error in language change handler:", error);
  }
};
</script>

<template>
  <select
    aria-label="Select language"
    class="inline-block"
    @change="onChangeHandler"
    :id="'language-switcher' + type"
  >
    <option
      v-for="language in languages"
      :key="language.id"
      :value="language.id"
      :selected="languageIdChain.includes(language.id)"
      :label="language.translationCode.code.substring(0, 2).toUpperCase()"
    >
      {{ language.translationCode.code.substring(0, 2).toUpperCase() }}
    </option>
  </select>
</template>

<style scoped>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 38%;
  margin-right: 0rem;
  padding-right: 1.5rem;
  border: none;
}
</style>
