<script setup lang="ts">
const { count } = useCart();
const { count: wishlistCount } = useWishlist();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const miniCartModal = useMiniCartModal();

const openSearchMenu = ref(false);
const openBurgerMenu = ref(false);
const openServiceMenu = ref(false);

const closeBurgerMenu = () => {
  openBurgerMenu.value = false;
};
</script>

<template>
  <div class="header-wrapper">
    <header class="group bg-skin-white py-[0.660rem] fixed w-full top-0 z-50">
      <div class="container max-w-[100rem] px-5 xl:px-10">
        <div class="flex items-center">
          <div class="flex items-center md:max-w-none max-w-[5rem]">
            <NuxtLink :to="formatLink(`/`)">
              <NuxtImg src="/images/logo.svg" alt="BIO CIRCLE" width="138" />
            </NuxtLink>
          </div>

          <nav
            id="NavBar"
            :class="{ open: openBurgerMenu }"
            class="group ms-auto bg-skin-white flex flex-col md:flex-row uppercase prose-ul:list-none min-w-[180px]"
          >
            <div
              id="HeaderMenu"
              :class="{
                open: openBurgerMenu,
                hidden: !openBurgerMenu,
              }"
              class="flex xl:min-w-[901px] laptop:items-center flex-col laptop:flex-row fixed md:absolute laptop:static top-0 md:top-full left-0 bg-skin-white w-full laptop:w-auto laptop:!flex h-full laptop:h-auto pt-20 md:pt-0 laptop-max:h-[calc(100vh_-_3.75rem)] laptop-max:overflow-auto transition-all md:transition-none duration-300 opacity-0 laptop:opacity-100 pointer-events-none md:pointer-events-auto group-[&.open]:opacity-100 group-[&.open]:pointer-events-auto [&.open]:opacity-100"
            >
              <div
                class="group-[.open]:block md:!hidden bg-skin-white py-[0.660rem] px-5 fixed w-full top-0 left-0 h-20 z-10"
              >
                <NuxtLink
                  :to="formatLink(`/`)"
                  class="block w-[5rem]"
                  @click="closeBurgerMenu"
                >
                  <NuxtImg
                    src="/images/logo.svg"
                    class="w-[5rem]"
                    alt="BIO CIRCLE"
                  />
                </NuxtLink>
              </div>

              <LayoutTopNavigation
                :open="openBurgerMenu"
                @close-menu="closeBurgerMenu"
              />
              <LayoutServiceNavigation
                :open="openServiceMenu"
                @close-menu="closeBurgerMenu"
              />
            </div>

            <ul
              class="group-[&.open]:fixed md:!static w-full min-h-[40px] xl:h-[40px] md:w-[271px] bg-skin-white bottom-0 left-0 group-[&.open]:p-5 flex flex-wrap items-center text-skin-secondary [&>li>a]:w-8 [&>li>a]:h-8 [&>li>a]:bg-skin-icon [&>li>a]:rounded-full [&>li>button]:w-8 [&>li>button]:h-8 [&>li>button]:bg-skin-icon [&>li>button]:rounded-full gap-x-4 pl-5 prose-a:flex prose-a:items-center prose-a:justify-center md:min-w-[271px]"
            >
              <li class="inline-block w-[32px]">
                <button
                  id="btnSearch"
                  class="group [&.toggle]:bg-skin-theme flex items-center justify-center"
                  @click="openSearchMenu = !openSearchMenu"
                >
                  <img
                    src="/icons/icon-search.svg"
                    alt=""
                    class="block group-[&.toggle]:hidden"
                  />
                  <img
                    src="/icons/icon-close-white.svg"
                    alt=""
                    class="hidden group-[&.toggle]:block"
                  />
                </button>
              </li>
              <li class="inline-block w-[32px]">
                <AccountMenu />
              </li>
              <li class="w-[32px] group-[&.open]:block hidden md:inline-block">
                <NuxtLink
                  class="relative"
                  aria-label="wishlist"
                  data-testid="wishlist-button"
                  :to="formatLink(`/wishlist`)"
                  @click="closeBurgerMenu"
                >
                  <img src="/icons/icon-fav.svg" alt="" />
                  <client-only>
                    <div
                      v-if="wishlistCount"
                      class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-skin-theme border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900"
                    >
                      {{ wishlistCount }}
                    </div>
                  </client-only>
                </NuxtLink>
              </li>
              <li class="w-[32px] block md:inline-block">
                <a
                  href="javascript:void(0)"
                  class="cart relative"
                  @click="miniCartModal.open"
                >
                  <img src="/icons/icon-cart.svg" alt="" />
                  <div
                    v-if="count"
                    class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-skin-theme border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900"
                  >
                    {{ count }}
                  </div>
                </a>
              </li>
              <li class="w-[32px] h-[32px] group-[&.open]:block hidden md:inline-block">
                <LanguageSwitcher />
              </li>
            </ul>
          </nav>
          <button
            type="button"
            id="MenuToggle"
            @click="openServiceMenu = !openServiceMenu"
            class="group min-w-[1.75rem] h-7 ml-2 text-skin-white text-xl hidden laptop:block desktop:hidden bg-humburger [&.open]:!bg-humburger-close bg-no-repeat bg-[center] [&.open]:bg-[length:1.5rem]"
          ></button>
          <button
            type="button"
            id="NavToggle"
            @click="openBurgerMenu = !openBurgerMenu"
            class="group min-w-[1.75rem] h-7 ml-0 text-skin-white text-xl block laptop:hidden bg-humburger [&.open]:!bg-humburger-close bg-no-repeat bg-[center] [&.open]:bg-[length:1.5rem] relative z-50"
            :class="{
              '[&.open]:bg-[length:1.5rem] relative z-50 open': openBurgerMenu,
            }"
          ></button>
        </div>
      </div>
      <LayoutStoreSearch
        v-if="openSearchMenu"
        @link-clicked="openSearchMenu = false"
      />
    </header>

    <CheckoutSideCart :controller="miniCartModal" />
  </div>
</template>
