
import * as ipxRuntime$iNTwEqlWq7 from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as vercelRuntime$b8uWPRx1qM from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "listing": {
      "modifiers": {
        "height": 227,
        "fit": "fill",
        "format": "webp"
      }
    }
  },
  "provider": "vercel",
  "domains": [
    "bio-circle-backend.go2flow.dev",
    "cp.bio-circle.ch"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$iNTwEqlWq7, defaults: {} },
  ['vercel']: { provider: vercelRuntime$b8uWPRx1qM, defaults: {} }
}
        