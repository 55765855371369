import type { Schemas } from "#shopware";
import { useCartFunction as swUseCart } from "@shopware/composables";

const _useCart = (): UseCartReturn & {
  taxRate: ComputedRef<number>;
  totalNet: ComputedRef<number>;
} => {
  const useCartData: UseCartReturn = swUseCart();

  /**
   * Example on how to override the default `refreshCart` method
   *
   * @param {Schemas["Cart"]} newCart
   * @returns
   */
  async function refreshCart(
    newCart?: Schemas["Cart"]
  ): Promise<Schemas["Cart"]> {
    return useCartData.refreshCart(newCart);
  }

  const taxRate = computed(() => {
    const taxRules = useCartData.cart.value?.price?.taxRules;

    if (!taxRules) {
      return 0;
    }

    return taxRules[0]?.taxRate || 0;
  });

  const totalNet = computed(() => {
    return useCartData.cart.value?.price?.netPrice || 0;
  });

  return {
    ...useCartData,
    refreshCart,
    taxRate,
    totalNet,
  };
};

export const useCart = createSharedComposable(_useCart);
