
export function readLocalStorage() {
    if (import.meta.client) {
        // Read values from local storage
        const prefix = localStorage.getItem('prefix');
        const firstName = localStorage.getItem('firstName');
        const lastName = localStorage.getItem('lastName');
        const street = localStorage.getItem('street');
        const postcode = localStorage.getItem('postcode');
        const city = localStorage.getItem('city');

        // Create an object with the retrieved values
        return {
            prefix: prefix,
            firstName: firstName,
            lastName: lastName,
            street: street,
            postcode: postcode,
            city: city,
        };
    }
    return {};
}

export function getSortedOrders(orders: any) {
    if (orders && orders.length > 0) {
        orders.sort((a: any, b: any) => b.orderNumber - a.orderNumber);
    }
    return orders
}

export function removeProductById(productId: any, products: any) {

    for (let i = 0; i < products.length; i++) {
        if (products[i].id === productId) {
            products.splice(i, 1);
            break; // Exit the loop after removing the product
        }
    }

    return products;
}

export async function recreateCart() {
    const storedPersonData = localStorage.getItem('unPaidCart');
    if (typeof storedPersonData === "string") {
        const cartItems = JSON.parse(storedPersonData);

        for (const item of cartItems) {
            const { addToCart, isInCart, quantity } = useAddToCart(item);
            quantity.value = item.quantity;
            if(!isInCart.value) {
                await addToCart();
            }
        }
    }
}

export function rearrangeArray(array: any) {
    // Move '2nd image' (index 1) to the start
    array.splice(0, 0, array.splice(1, 1)[0]);

    if (array.length === 3) {
        // Move 'Third image' (index 2) to the second position
        array.splice(1, 0, array.splice(2, 1)[0]);
    }

    return array;
}

// Function to escape regular expression characters in sanitized words
function escapeRegExp(string: any) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function combineProductProperties(properties: any) {
    const combinedData: any = {};

    for (const property of properties) {
        const groupId = property?.groupId;
        const groupName = property.group?.translated?.name;

        const matchedOptions = properties.filter((option: any) => option?.groupId === groupId && option.group.visibleOnProductDetailPage === true);

        if (matchedOptions.length > 0) {
            combinedData[groupName] = matchedOptions.map((option: any) => option.translated.name).join(', ');
        }
    }

    return combinedData;
}