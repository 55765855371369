<script setup lang="ts">
const { getFormattedPrice, currencyCode } = usePrice();
const props = defineProps<{
  value: number | undefined;
}>();

const getPrice = computed<string>(() => {
  // let numericPrice = parseFloat(
  //   getFormattedPrice(props.value).replace(/\./g, "").replace(",", ".")
  // );
  const formattedPrice = new Intl.NumberFormat("de-CH", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(props.value || 0);

  return `${currencyCode.value} ${formattedPrice}`;
});
</script>

<template>
  <div>
    <slot name="beforePrice" />
    <span>{{ getPrice }}</span>
    <slot name="afterPrice" />
  </div>
</template>
