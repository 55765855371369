<script setup lang="ts">
const props = defineProps<{
  controller: ReturnType<typeof useModal>;
}>();

const { cartItems, isEmpty, taxRate, subtotal } = useCart();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { taxState } = useSessionContext();

const subTotalNet = computed(() => {
  if (taxState.value === "net") return subtotal.value;
  return subtotal.value / (1 + taxRate.value / 100);
});
</script>

<template>
  <LayoutSidebar :controller="props.controller" side="right">
    <aside class="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
      <div class="flex items-start justify-between">
        <h2
          id="slide-over-title"
          class="text-lg font-medium text-secondary-900 py-0"
        >
          {{ $t("cart.title") }}
        </h2>
        <div class="ml-3 flex h-7 items-center">
          <button
            type="button"
            class="-m-2 p-2 pt-2 pb-1 bg-transparent text-gray-500 hover:text-pink border"
            data-testid="cart-close-button"
            @click="props.controller.close"
          >
            <span class="sr-only">Close panel</span>
            <div class="w-6 h-6 fa-solid fa-close" />
          </button>
        </div>
      </div>
      <hr class="border-t border-skin-theme mt-5" />

      <div class="mt-8">
        <div class="flow-root">
          <ul
            v-if="!isEmpty"
            role="list"
            class="-my-6 px-0 divide-y divide-secondary-200"
          >
            <li
              v-for="cartItem in cartItems"
              :key="cartItem.id"
              class="flex py-6"
            >
              <CheckoutCartItem :cart-item="cartItem" :is-flyout="true" />
            </li>
          </ul>
          <div v-else class="text-2xl text-center">
            {{ $t("cart.empty") }}
          </div>
        </div>
      </div>
    </aside>

    <aside
      aria-label="Side Cart Links"
      class="border-t border-secondary-200 py-6 px-4 sm:px-6"
    >
      <div
        class="flex justify-between text-base font-medium text-secondary-900"
      >
        <p>{{ $t("cart.subtotal") }}</p>
        <SharedPrice :value="subTotalNet" data-testid="cart-subtotal" />
      </div>
      <p class="mt-2 text-sm text-secondary-500">
        {{ $t("cart.tax_info") }}
      </p>
      <div class="mt-6 items-center justify-center text-center">
        <NuxtLink
          class="py-3 btn-outline m-auto w-full"
          :class="{
            '!text-[#bcc1c7] bg-[#f9f9f9] cursor-default': isEmpty,
            'bg-primary  hover:bg-dark': !isEmpty,
          }"
          @click="props.controller.close"
          :to="formatLink(isEmpty ? '' : '/checkout')"
          data-testid="cart-checkout-link"
        >
          {{ $t("cart.checkout.button") }}
        </NuxtLink>
      </div>
      <div class="mt-2 justify-center text-center text-sm text-dark">
        <NuxtLink
          class="py-3 btn-outline m-auto w-full"
          :to="formatLink(`/checkout/cart`)"
          data-testid="cart-checkout-shopping-cart"
          @click="props.controller.close"
        >
          {{ $t("cart.checkout") }}
        </NuxtLink>
      </div>
      <div class="mt-6 flex justify-center text-center text-sm text-dark">
        <p>
          {{ $t("cart.or") }}
          <button
            type="button"
            class="font-medium bg-transparent"
            data-testid="cart-continue-button"
            @click="props.controller.close"
          >
            {{ $t("cart.continue_shopping.button") }}
            <span aria-hidden="true"> &rarr;</span>
          </button>
        </p>
      </div>
    </aside>
  </LayoutSidebar>
</template>
