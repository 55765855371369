<script setup lang="ts">
import type { NuxtError } from "#app";
import type { RouteObject } from "@shopware/composables";

// Navigation for default theme (depth 1 is 2 level navi, depth 2 is 3 level navi, and so on)
const { loadNavigationElements } = useNavigation();
const { data } = useAsyncData("mainNavigation", () => {
  return loadNavigationElements({ depth: 2 });
});
provide("swNavigation-main-navigation", data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});
const { data: footerData } = useAsyncData("mainFooterNavigation", () => {
  return loadFooterNavigationElements({ depth: 2 });
});
provide("swNavigation-footer-navigation", footerData);

const { loadNavigationElements: loadTopNavigationElements } = useNavigation({
  type: "service-navigation",
});
const { data: topNavigationData } = useAsyncData( "mainServiceNavigation", ()=> {
  return loadTopNavigationElements({depth: 2})
})
provide("swNavigation-service-navigation", topNavigationData)

defineOptions({
  name: "ErrorPage",
});

const props = defineProps<{
  error: NuxtError<{
    statusCode: number;
    statusMessage: string;
    message: string;
  }>;
}>();

const isMaintenanceMode = computed(() => {
  return props.error.statusMessage === "MAINTENANCE_MODE";
});

let isFormattedError = true;
let errMessage = "";
let linkFormatter = (path: string | RouteObject) => path;

try {
  const { t } = useI18n();
  const localePath = useLocalePath();
  const { formatLink } = useInternationalization(localePath);
  linkFormatter = formatLink;

  const errorMessageMap: { [key: number]: string } = {
    404: t("errorPages.404"),
    408: t("errorPages.408"),
    500: t("errorPages.500"),
    502: t("errorPages.502"),
    503: t("errorPages.503"),
  };

  errMessage =
    errorMessageMap[props.error.statusCode as keyof typeof errorMessageMap] ||
    props.error.message;

  console.error("[error.vue]:", props.error?.message);

  if (props.error.statusCode === 412) {
    // setting a timeout here to ensure we are the last error message in terminal
    setTimeout(() => {
      console.error(
        "Looks like your API connection is not working. Check your _nuxt.config.ts_ configuration (shopware.endpoint and shopware.accessToken). 🤞",
      );
      console.error(
        "For more help ➡️  https://frontends.shopware.com/resources/troubleshooting.html",
      );
    }, 2.0 * 1000);
  }
} catch (e) {
  console.error("Problem with loading error page", e);
  isFormattedError = false;
}

const statusCode = isFormattedError ? props.error.statusCode : "Error";
const errorMessage = isFormattedError ? errMessage : props.error.message;

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
</script>

<template>
  <div class="wrap">
    <div class="wrapper">
      <LayoutHeader />
      <ErrorsMaintainMode v-if="isMaintenanceMode" />
      <div
        v-else
        class="px-5 py-3 md:py-20 md:px-32 lg:px-24 lg:py-24 items-center flex justify-center flex-col-reverse lg:flex-row mt-8 bg-skin-grey-light"
      >
        <div class="flex flex-col items-center justify-center my-8">
          <div class="max-w-md text-center">
            <h1 class="mb-4 font-extrabold text-9xl">
              <span class="sr-only">{{ $t("error") }}</span
              >{{ statusCode }}
            </h1>
            <p
              v-if="errorMessage"
              class="text-xl md:text-3xl font-semibold mt-4 mb-6"
            >
              {{ errorMessage }}
            </p>

            <p v-if="statusCode == 404">{{ $t("errorPages.404.explain") }}</p>

            <RouterLink :to="formatLink('/')" class="link-active link-exact-active text-primary btn mt-6 inline-flex relative hover:pr-16 group overflow-hidden">
              {{ $t("home.page.back") }}
              <span class="ml-3 absolute right-6 opacity-0 group-hover:opacity-100 transition-all duration-300"><img src="/assets/icons/btn-arrow.svg" alt="arrow-right"></span>
            </RouterLink>

          </div>
        </div>
        <div class="flex items-center justify-center">
          <img
            class="w-full h-full max-w-md max-h-md"
            src="~/assets/error-background.png"
            alt="Error"
          />
        </div>
      </div>
      <LayoutFooter />
    </div>
  </div>
</template>
