import { ref, provide, inject, reactive } from "vue";
import { useNuxtApp, useRouter } from "#imports";

// Create a global state object to ensure consistency across components
const historyState = reactive({
  isBackNavigation: false,
  previousRoute: null as string | null,
  currentRoute: null as string | null,
});

export function useHistoryNavigation() {
  const router = useRouter();

  // Track navigation direction
  const trackNavigation = () => {
    if (typeof window === "undefined") return;

    // Store the current path when navigating
    const storeCurrentPath = () => {
      historyState.previousRoute = historyState.currentRoute;
      historyState.currentRoute =
        window.location.pathname + window.location.search;
    };

    // On initial page load
    storeCurrentPath();

    // Track navigation changes
    router.beforeEach((to, from) => {
      historyState.isBackNavigation = false;
      return true;
    });

    router.afterEach((to, from) => {
      storeCurrentPath();
    });

    // Listen to popstate for back/forward button clicks
    window.addEventListener("popstate", () => {
      historyState.isBackNavigation = true;
    });
  };

  // Set up function to provide the navigation state globally
  const provideHistoryNavigation = () => {
    if (typeof window !== "undefined") {
      trackNavigation();
    }

    provide("historyState", historyState);
    return { historyState };
  };

  // Function to use the history state in components
  const useHistoryState = () => {
    const state = inject("historyState", historyState);
    return {
      isBackNavigation: state.isBackNavigation,
      historyState: state,
    };
  };

  return {
    historyState,
    provideHistoryNavigation,
    useHistoryState,
  };
}
