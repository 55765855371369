<script setup lang="ts">
import {
  getCategoryRoute,
  getTranslatedProperty,
} from "@shopware/helpers";
import type { Schemas } from "#shopware";
type NavigationElement = Schemas["Category"] & {
  activeClass?: boolean;
  row?: any
};

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const props = defineProps<{
  navigationElementChildren: Array<NavigationElement>;
}>();

const emits = defineEmits<{
  (
    e: "updateActiveClass",
    navigationId: string,
    parentId: string | undefined,
  ): void;
  (e: "focusoutLastElement", lastElement: boolean): void;
}>();

const emitUpdateActiveClass = (
  navigationId: string,
  parentId: string | undefined,
) => {
  emits("updateActiveClass", navigationId, parentId);
};

</script>

<template>
  <section>
    <template
      v-for="(childElement, index) in navigationElementChildren"
      :key="childElement.id"
    >
      <li class="relative px-4 md:px-0">
        <NuxtLink
          :to="formatLink(getCategoryRoute(childElement))"
          :target="childElement.externalLink || childElement.linkNewTab ? '_blank' : ''"
          :class="{'active': childElement.activeClass,}"
          @click="emitUpdateActiveClass(childElement.id, childElement.parentId)"
        >
          {{ getTranslatedProperty(childElement, "name") }}
        </NuxtLink>
        <template
          v-if="childElement.children && childElement.children.length > 0"
        >
          <LayoutTopNavigationRecursive
            :navigation-element-children="childElement.children"
            @update-active-class="emitUpdateActiveClass(childElement.id, childElement.parentId)"
          />
        </template>
      </li>
    </template>
  </section>
</template>

<style scoped>

</style>
