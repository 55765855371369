<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";

const { isLoggedIn, logout, user } = useUser();
const accountMenu = ref(null);
const router = useRouter();

const loginModalController = useModal();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const isAccountMenuOpen = ref(false);

onClickOutside(accountMenu, () => (isAccountMenuOpen.value = false));

async function invokeLogout() {
  try {
    await logout();
  } finally {
    isAccountMenuOpen.value = false;
  }
}

async function invokeResetPassword() {
  await router.push(formatLink("/account/recover"));
  loginModalController.close();
}
</script>
<template>

    <ClientOnly>
      <a
          v-show="!isLoggedIn"
          class="relative"
          data-testid="header-sign-in-link"
          @click="loginModalController.open"
      >
        <NuxtImg src="/icons/icon-user.svg" alt="" width="16" style="max-width: 100%" />
      </a>
      <SharedModal :controller="loginModalController">
        <AccountLoginForm
            @close="loginModalController.close"
            @success="loginModalController.close"
        >
          <div class="flex items-center justify-end">
            <div class="text-sm">
              <NuxtLink
                  class="font-medium text-black hover:text-skin-theme cursor-pointer"
                  @click.stop="invokeResetPassword"
              >
                {{$t('account.changePassword')}}
              </NuxtLink>
            </div>
          </div>
        </AccountLoginForm>
      </SharedModal>
      <NuxtLink v-if="isLoggedIn"
          :to="formatLink('/account')"
          type="button"
          class="text-xl md:text-base text-white bg-transparent focus:outline-none block"
          data-testid="account-menu-hello-button"
      >
        <img src="/icons/icon-user.svg" alt="icon user">
      </NuxtLink>
    </ClientOnly>
</template>
