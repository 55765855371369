<script setup lang="ts">
import { getProductRoute } from "@shopware/helpers";
import { useFocus, useMagicKeys } from "@vueuse/core";
import { useTemplateRef } from "vue";

withDefaults(
  defineProps<{
    displayTotal?: number;
  }>(),
  { displayTotal: 10 }
);

const emit = defineEmits<(e: "link-clicked") => void>();

const { searchTerm, search, getProducts, getTotal, loading } =
  useProductSearchSuggest();

const active = ref(false);

const searchContainer = useTemplateRef("searchContainer");
const searchInput = useTemplateRef("searchInput");
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

watch(active, (value) => {
  const { focused } = useFocus(searchInput);
  focused.value = value;
});

const typingQuery = ref("");

watch(typingQuery, (value) => {
  if (value.length >= 1) {
    performSuggestSearch(value);
  }
});

const performSuggestSearch = useDebounceFn((value) => {
  searchTerm.value = value;
  search();
}, 300);

const showSuggest = computed(() => {
  return typingQuery.value.length >= 1 && active.value;
});

const { enter, escape } = useMagicKeys({ target: searchInput });
const { push } = useRouter();

const sideMenuModal = useSideMenuModal();
const route = useRoute(); // Current route information
const gotoSearch = () => {
  if (typingQuery.value.length < 1) return;
  sideMenuModal.close();
  emit("link-clicked");
  active.value = false;
  const currentLocale = route.path.match(/^\/[a-z]{2}-[A-Z]{2}/)?.[0] || "";  
  push(`${currentLocale}/search?search=${encodeURIComponent(typingQuery.value)}`);
};

watch(enter, (value) => {
  if (!value) return;
  gotoSearch();
});

watch(escape, (value) => {
  if (!value) return;
  active.value = false;
  emit("link-clicked");
});
</script>

<template>
  <div class="store-search-wrapper">
    <div
      id="SearchBar"
      class="absolute w-full top-full left-0 bg-skin-theme py-[1.875rem] hidden"
    >
      <div class="container">
        <form action="#">
          <div
            class="flex w-full relative border border-skin-white rounded-l-[0.1875rem] rounded-r-full bg-skin-white/10"
          >
            <input
              type="text"
              placeholder="Search"
              class="min-w-[50%] h-[3.44rem] pl-5 pr-14 text-skin-white placeholder:text-skin-white bg-[transparent] !ring-transparent flex-1 border-0 bg-search-white bg-no-repeat bg-left-center-5 pl-[2.875rem]"
              required
            />
            <input
              type="submit"
              :value="$t('form.search')"
              class="h-[3.44rem] px-[2.2rem] py-[1.15rem] text-skin-theme text-sm uppercase rounded-full bg-skin-white font-Helvetica cursor-pointer font-bold right-0"
            />
          </div>
        </form>
      </div>
    </div>

    <div
      ref="searchContainer"
      id="SearchBar"
      class="absolute w-full top-full left-0 bg-skin-theme py-[1.875rem]"
    >
      <div class="container">
        <div
          class="flex w-full relative border border-skin-white rounded-full bg-skin-white/10"
        >
          <input
            ref="searchInput"
            v-model="typingQuery"
            data-testid="layout-search-input"
            type="search"
            aria-label="Search for products"
            class="min-w-[50%] h-[3.44rem] pl-5 pr-14 text-skin-white placeholder:text-skin-white bg-[transparent] !ring-transparent flex-1 border-0 bg-search-white bg-no-repeat bg-left-center-5 pl-[2.875rem] pt-[14px]"
            :placeholder="$t('form.searchPlaceholder')"
            @keydown.enter="$event.preventDefault()"
            @click="active = true"
          />
          <input
            type="button"
            @click="gotoSearch"
            :value="$t('form.search')"
            class="h-[3.44rem] px-[2.2rem] py-[1.15rem] text-skin-theme text-sm uppercase rounded-full bg-skin-white font-Helvetica cursor-pointer font-bold right-0"
          />
        </div>
      </div>
      <div
        v-if="showSuggest"
        data-testid="layout-search-result-box"
        class="absolute border-secondary-100 bg-white border-t-1 duration-300 left-0 overflow-hidden right-0 rounded-b-md shadow-md transition-height w-auto z-10 mt-[30px]"
      >
      <div class="text-center absolute min-w-full" v-if="loading">
                <div class="fa-solid fa-spinner animate-spin mt-2"/>
              </div>
        <div
          class="mx-auto container px-5 xl:px-5 py-3 xl:py-7 max-h-[450px] overflow-auto"
        >
          <NuxtLink
            v-for="(product, index) in getProducts?.slice(0, displayTotal)"
            :key="product.id"
            :to="formatLink(getProductRoute(product))"
            data-testid="layout-search-suggest-link"
            @click="[(active = false), $emit('link-clicked')]"
          >
            <ProductSuggestSearch v-if="index < 8" :product="product" />
          </NuxtLink>

          <div
            class="h-11 text-base mt-2 rounded-b-md p-3 text-center transition text-black"
            style="clip-path: inset(0% 0% 0% 0%)"
            :class="[loading ? ['bg-white'] : ['bg-white']]"
          >
            <div class="capitalize text-right hover:text-skin-theme">
              <NuxtLink
                v-if="getTotal > 0"
                data-testid="layout-search-result-box-more-link"
                :to="
                  formatLink({
                    path: `/search`,
                    query: { search: typingQuery },
                  })
                "
                @click="[(active = false), $emit('link-clicked')]"
              >
                {{ $t("search.see") }}
                <span v-if="getTotal !== 1">{{ $t("search.all") }}</span>
                {{ getTotal }}
                <span>{{ $t("search.result", getTotal) }}</span>
              </NuxtLink>
              <div 
                v-else-if="getTotal === 0" 
                data-testid="layout-search-result-box-no-result" 
                class="text-center"
              >
                {{ $t("search.noResults") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="search-overlay h-full w-full fixed bg-black bg-opacity-60 z-[-1]"
        @click="$emit('link-clicked')"
      ></div>
    </div>
  </div>
</template>