<script setup lang="ts">
import { getCategoryRoute, getTranslatedProperty } from "@shopware/helpers";
import type { Schemas } from "#shopware";
import { onKeyStroke, useActiveElement } from "@vueuse/core";
type NavigationElement = Schemas["Category"] & {
  activeClass?: boolean;
  children?: NavigationElement[];
};

const props = defineProps<{
  open?: boolean;
}>();

const { navigationElements } = useNavigation();
const currentMenuPosition = ref<string | undefined>(undefined);
const resetActiveClass = ref<boolean>(true);

const route = useRoute();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const activeSubmenu = ref<string | null>(null);

const beforeEnter = (el: any) => {
  el.style.height = "0";
};

const enter = (el: any) => {
  el.style.height = el.scrollHeight + "px";
};

const beforeLeave = (el: any) => {
  el.style.height = el.scrollHeight + "px";
};

const leave = (el: any) => {
  el.style.height = "0";
};

const emit = defineEmits<{
  'close-menu': []
}>();

onMounted(() => {
  let currentNaviagtionElement: NavigationElement | undefined;
  if (navigationElements.value) {
    currentNaviagtionElement = findNavigationElement(
      route.path.slice(1),
      navigationElements.value
    );
  }
  if (currentNaviagtionElement) {
    updateActiveClass(currentNaviagtionElement.id, currentNaviagtionElement.parentId);
  }
});

const findNavigationElement = (
  routePath: string,
  navigation: NavigationElement[]
): NavigationElement | undefined => {
  for (const navigationElement of navigation) {
    const seoUrls = navigationElement.seoUrls as Schemas["SeoUrl"][] | undefined;
    if (seoUrls?.some(url => url.seoPathInfo === routePath)) {
      return navigationElement;
    }
    
    if (navigationElement.children?.length) {
      const found = findNavigationElement(routePath, navigationElement.children);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

const onUpdateActiveClass = (navigationId: string, parentId: string | undefined) => {
  updateActiveClass(navigationId, parentId);
};

const resetNavigationActiveClass = (navigation: NavigationElement[]) => {
  for (let ni = 0; ni < navigation.length; ++ni) {
    navigation[ni].activeClass = false;
    const children = navigation[ni].children;
    if (children) {
      resetNavigationActiveClass(children);
    }
  }
};

const updateActiveClass = (navigationId: string, parentId: string | undefined) => {
  const setNavigationActiveClass = (
    navigation: NavigationElement[],
    navigationId: string,
    parentId: string | undefined
  ) => {
    for (let ni = 0; ni < navigation.length; ++ni) {
      if (navigation[ni].id === navigationId) {
        navigation[ni].activeClass = true;
      }
      if (navigation[ni].id == parentId) {
        navigation[ni].activeClass = true;
        if (navigationElements.value) {
          setNavigationActiveClass(
            navigationElements.value,
            navigationId,
            navigation[ni].parentId
          );
        }
      }
      const children = navigation[ni].children;
      if (children) {
        setNavigationActiveClass(children, navigationId, parentId);
      }
    }
  };

  if (navigationElements.value) {
    resetNavigationActiveClass(navigationElements.value);
    setNavigationActiveClass(navigationElements.value, navigationId, parentId);
    resetActiveClass.value = false;
  }
};
//#region - keyboard navigation (highliy connected to the HTML structure)
const activeElement = useActiveElement();
onKeyStroke("ArrowDown", (e) => {
  // next parent link or next child link
  if (
    activeElement.value?.parentElement?.nextElementSibling?.getElementsByTagName("a")[0]
  ) {
    e.preventDefault();
    activeElement.value.parentElement.nextElementSibling
      .getElementsByTagName("a")[0]
      .focus();
    return;
  }
});

onKeyStroke("ArrowUp", (e) => {
  // previous parent link or previous child link
  if (
    activeElement.value?.parentElement?.previousElementSibling?.getElementsByTagName(
      "a"
    )[0]
  ) {
    e.preventDefault();
    activeElement.value?.parentElement.previousElementSibling
      .getElementsByTagName("a")[0]
      .focus();
    return;
  }
});
//#endregion - keyboard navigation

// reset when route.path changes
watch(
  () => route.path,
  (newPath) => {
    if (navigationElements.value) {
      resetNavigationActiveClass(navigationElements.value);
      
      const currentNaviagtionElement = findNavigationElement(
        newPath.slice(1),
        navigationElements.value
      );
      
      if (currentNaviagtionElement) {
        updateActiveClass(currentNaviagtionElement.id, currentNaviagtionElement.parentId);
      }
    }
    currentMenuPosition.value = undefined;
  },
  { immediate: true }
);

const toggleSubMenu = (navigationId: string) => {
  if (activeSubmenu.value === navigationId) {
    activeSubmenu.value = null;
  } else {
    activeSubmenu.value = navigationId;
  }
};

const isActive = (element: NavigationElement): boolean => {
  if (element.activeClass) return true;
  
  if (element.children) {
    return element.children.some(child => isActive(child));
  }
  
  return false;
};

const handleNavigationClick = (navigationId: string, parentId: string | undefined) => {
  updateActiveClass(navigationId, parentId);
  emit('close-menu');
};
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <ul
    :class="{ open: open }"
    class="nav-menu1 xl:h-[20px] flex laptop:flex-row flex-wrap laptop:items-center text-skin-secondary [&>li>a]:block [&>li>a]:px-5 xl:[&>li>a]:px-5 [&>li:hover>a]:text-skin-theme laptop:border-r border-skin-primary font-bold text-sm [&>li>.menu:before]:content-[''] [&>li>.menu:before]:absolute [&>li>.menu:before]:w-full [&>li>.menu:before]:left-0 [&>li>.menu:before]:h-8 [&>li>.menu:before]:-top-8 laptop:[&>li>span]:hidden [&>li]:w-full laptop:[&>li]:w-auto flex-col [&>li>a]:py-4 laptop:[&>li>a]:py-0 [&>li>a]:max-w-[90%] laptop:[&>li>a]:max-w-none"
  >
    <template
      v-for="(navigationElement, index) in navigationElements"
      :key="navigationElement.id"
      @mouseover="currentMenuPosition = navigationElement.id"
      @focusin="currentMenuPosition = navigationElement.id"
    >
      <li class="menu-item group [&:hover>.menu]:opacity-100 [&:hover>.menu]:pointer-events-auto" >
        <span
          v-if="navigationElement.children?.length > 0"
          @click="toggleSubMenu(navigationElement.id)"
          class="toggle_sub-menu inline-block w-12 h-12 bg-dropdown bg-no-repeat bg-[center] desktop:hidden absolute right-3 top-0"
          :class="{ 'rotate-180': activeSubmenu === navigationElement.id }"
        ></span>
        <NuxtLink
          role="menuitem"
          :target="
            navigationElement.externalLink || navigationElement.linkNewTab ? '_blank' : ''
          "
          :to="formatLink(getCategoryRoute(navigationElement))"
          class="uppercase"
          :class="{ 'active': isActive(navigationElement) }"
          @click="handleNavigationClick(navigationElement.id, navigationElement.parentId)"
        >
          {{ getTranslatedProperty(navigationElement, "name") }}
        </NuxtLink>
        <client-only>
          <LayoutMegaMenu
            :navigationElement="navigationElement"
            :activeSubMenu="activeSubmenu"
            @close-menu="$emit('close-menu')"
          ></LayoutMegaMenu>
        </client-only>
      </li>
    </template>
  </ul>
</template>

<style scoped>
.submenu-enter-active,
.submenu-leave-active {
  transition: all 0.3s ease-out;
  overflow: hidden;
}

.submenu-enter-from,
.submenu-leave-to {
  height: 0;
  opacity: 0;
}

.toggle_sub-menu {
  transition: transform 0.3s ease;
}

.active {
  color: var(--color-theme);
}

a.active {
  color: var(--color-theme);
}
</style>
