<script setup lang="ts">
import { getTranslatedProperty, getCategoryRoute } from "@shopware/helpers";

const { navigationElements } = useNavigation({ type: "footer-navigation" });
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { languageIdChain } = useSessionContext();

const isGerman = computed(() => {
  return languageIdChain.value === "2fbb5fe2e29a4d70aa5854ce7ce3e20b";
});

const certificatesPage = computed(() => {
  if (isGerman.value) {
    return formatLink("/making-green-work/unsere-labels/");
  }
  return formatLink("/making-green-work/nos-etiquettes/");
});
</script>

<template>
  <div class="footer-wrapper">
    <LayoutCustomFooter />

    <footer class="bg-skin-white lg:pt-7 lg:pb-14 py-8 text-skin-primary">
      <div class="container max-w-[100rem] px-5 xl:px-10">
        <div class="flex flex-col lg:flex-row gap-y-10 -mx-4">
          <div class="lg:w-[30%] px-4">
            <div class="mb-5">
              <NuxtLink :to="formatLink('/')">
                <NuxtImg
                  class="w-52"
                  loading="lazy"
                  src="/images/logo.svg"
                  alt="Bio Circle"
                />
              </NuxtLink>
            </div>
            <ul
              class="list-none mb-8 prose-li:mb-[1.875rem] prose-li:relative prose-li:text-sm prose-img:absolute prose-img:left-0 prose-img:-top-1 prose-li:pl-9"
            >
              <li>
                <img
                  src="/icons/icon-map-pin.svg"
                  class="w-[24px] h-[25px]"
                  alt="map"
                />
                {{ $t("footer.address1") }} <br />
                {{ $t("footer.address2") }} <br />
                {{ $t("footer.address3") }}
              </li>
              <li>
                <img
                  src="/icons/icon-phone.svg"
                  class="w-[24px] h-[25px]"
                  alt="phone"
                />
                {{ $t("footer.phone") }} <br />
                <a href="tel:+410418781166">+41 (0) 41 878 11 66</a>
              </li>
              <li>
                <img
                  src="/icons/icon-mail.svg"
                  class="w-[24px] h-[25px]"
                  alt="email"
                />
                {{ $t("footer.email") }}: <br />
                <a href="mailto:service@bio-circle.ch">service@bio-circle.ch</a>
              </li>
              <li>
                <img
                  src="/icons/icon-clock.svg"
                  class="w-[24px] h-[25px]"
                  alt="clock"
                />
                {{ $t("footer.workingHours1") }} <br />
                {{ $t("footer.workingHours2") }}
              </li>
            </ul>
            <ul
              class="flex flex-wrap items-center text-skin-secondary [&>li>a]:w-11 [&>li>a]:h-11 [&>li>a]:bg-skin-icon [&>li>a]:rounded-full gap-x-2.5 prose-a:flex prose-a:items-center prose-a:justify-center"
            >
              <li>
                <a :href="$t('footer.social.facebook')" target="_blank"
                  ><img src="/icons/icon-fb.svg" alt=""
                /></a>
              </li>
              <li>
                <a :href="$t('footer.social.instagram')" target="_blank"
                  ><img src="/icons/icon-insta.svg" alt=""
                /></a>
              </li>
              <li>
                <a :href="$t('footer.social.linkedin')" target="_blank"
                  ><img src="/icons/icon-linkedin.svg" alt=""
                /></a>
              </li>
            </ul>
          </div>
          <div
            class="lg:w-[22.5%] lg:pt-8 px-4"
            v-for="navigationElement in navigationElements"
            :key="navigationElement.id"
          >
            <h5 class="text-lg font-bold mb-8 uppercase">
              {{ getTranslatedProperty(navigationElement, "name") }}
            </h5>
            <template v-if="navigationElement.childCount > 0">
              <ul
                class="ftext-lg [&>li:not(:last-child)]:mb-2 hover:[&>li>a]:text-skin-theme"
              >
                <li
                  v-for="navigationChild in navigationElement.children"
                  :key="navigationChild.id"
                  class="pb-3 md:pb-1"
                >
                  <NuxtLink
                    :target="navigationChild.linkNewTab ? '_blank' : ''"
                    :to="formatLink(getCategoryRoute(navigationChild))"
                    class="text-base font-normal text-secondary-500 hover:text-secondary-900"
                  >
                    {{ getTranslatedProperty(navigationChild, "name") }}
                  </NuxtLink>
                </li>
              </ul>
            </template>
          </div>
          <div class="lg:w-1/4 lg:pt-8 px-4">
            <NuxtLink :to="certificatesPage">
              <h5 class="text-lg font-bold mb-8 uppercase">
                {{ $t("product.detail.certificates") }}
              </h5>
            </NuxtLink>
            <div class="flex flex-wrap gap-2.5">
              <NuxtLink :to="certificatesPage">
                <img src="/images/Siegel-1.png" alt="" />
              </NuxtLink>
              <NuxtLink :to="certificatesPage">
                <img src="/images/Siegel-2.png" alt="" />
              </NuxtLink>
              <template v-if="isGerman">
                <NuxtLink :to="certificatesPage">
                  <img src="/images/Siegel-3.png" alt="" />
                </NuxtLink>
              </template>
              <template v-else>
                <NuxtLink :to="certificatesPage">
                  <img src="/images/Siegel-3-fr.png" alt="" />
                </NuxtLink>
              </template>
              <template v-if="isGerman">
                <NuxtLink :to="certificatesPage">
                  <img src="/images/Siegel-4.png" alt="" />
                </NuxtLink>
              </template>
              <template v-else>
                <NuxtLink :to="certificatesPage">
                  <img src="/images/Siegel-4-fr.png" alt="" />
                </NuxtLink>
              </template>
              <NuxtLink :to="certificatesPage">
                <img src="/images/Siegel-5.png" alt="" />
              </NuxtLink>
              <NuxtLink :to="certificatesPage">
                <img src="/images/Siegel-6.png" alt="" />
              </NuxtLink>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-10 lg:mt-0">
          <NuxtImg
            format="webp"
            laoding="lazy"
            src="/images/cards.png"
            alt=""
            class="w-80 h-6 max-w-full lg:-mt-6"
          />
        </div>
      </div>
    </footer>
  </div>
</template>
