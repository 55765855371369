<script setup lang="ts">
import { onMounted } from 'vue';
import { getPrefix } from "./i18n/src/helpers/prefix";
import type { Schemas } from "#shopware";
const runtimeConfig = useRuntimeConfig();
import "vue3-carousel/dist/carousel.css";

const { locale } = useI18n();

/**
 * Init breadcrumbs context
 */
useBreadcrumbs();

useHead({
  title: runtimeConfig.public.general.shopTitle[locale.value],
  meta: [
    {
      name: runtimeConfig.public.general.name,
      content: runtimeConfig.public.general.description,
    },
  ],
  htmlAttrs: {
    lang: runtimeConfig.public.general.language,
  },
  bodyAttrs: {
    class:
      "w-full font-Helvetica text-skin-primary bg-skin-body [&.menu-open]:overflow-hidden laptop:static overflow-x-hidden",
  },
});

const { apiClient } = useShopwareContext();
const sessionContextData = ref<Schemas["SalesChannelContext"]>();
const contextResponse = await apiClient.invoke("readContext get /context");
sessionContextData.value = contextResponse.data;

useBroadcastChannelSync();

// If you enable runtimeConfig.shopware.useUserContextInSSR, then you can use this code to share session between server and client.
// const { data: sessionContextData } = await useAsyncData(
//   "sessionContext",
//   async () => {
//     return await getSessionContext(apiInstance);
//   }
// );

// read the locale/lang code from accept-language header (i.e. en, en-GB or de-DE)
// and set configuration for price formatting globally
const headers = useRequestHeaders();
// Extract the first (with highest priority order) locale or lang code from accept-language header
// for example: "en-US;q=0.7,en;q=0.3" will return "en-US"
const localeFromHeader = headers?.["accept-language"]
  ?.split(",")
  ?.map(
    (languageConfig) => languageConfig.match(/^([a-z]{2}(?:-[A-Z]{2})?)/)?.[0]
  )
  .find(Boolean);

usePrice({
  currencyCode: sessionContextData.value?.currency?.isoCode || "",
  localeCode: localeFromHeader,
});

useSessionContext(sessionContextData.value);

const { getWishlistProducts } = useWishlist();
const { refreshCart } = useCart();

useNotifications();
useAddress();

const { availableLocales, defaultLocale, localeProperties, messages } =
  useI18n();
const router = useRouter();
const {
  getAvailableLanguages,
  getLanguageCodeFromId,
  getLanguageIdFromCode,
  changeLanguage,
  languages: storeLanguages,
} = useInternationalization();
const { languageIdChain, refreshSessionContext } = useSessionContext();

const { data: languages } = await useAsyncData("languages", async () => {
  return await getAvailableLanguages();
});
let languageToChangeId: string | null = null;

if (languages.value?.elements.length && router.currentRoute.value.name) {
  storeLanguages.value = languages.value?.elements;
  // Prefix from url
  const prefix = getPrefix(
    availableLocales,
    router.currentRoute.value.name as string,
    defaultLocale
  );

  provide("cmsTranslations", messages.value[prefix || defaultLocale] ?? {});

  // Language set on the backend side
  if (localeProperties.value.localeId) {
    if (languageIdChain.value !== localeProperties.value.localeId) {
      languageToChangeId = localeProperties.value.localeId;
    }
  } else {
    const sessionLanguage = getLanguageCodeFromId(languageIdChain.value);

    // If languages are not the same, set one from prefix
    if (sessionLanguage !== prefix) {
      languageToChangeId = getLanguageIdFromCode(
        prefix ? prefix : defaultLocale
      );
    }
  }

  if (languageToChangeId) {
    await changeLanguage(languageToChangeId);
    await refreshSessionContext();
  }

  locale.value = prefix ? prefix : defaultLocale;
  // Set prefix from CMS components
  provide("urlPrefix", prefix);

  useHead({
    htmlAttrs: {
      lang: locale.value,
    }
  });
}

if (import.meta.client) {
  // getting the wishlist products should not block SSR
  if (!(router.currentRoute.value.name as string).includes("wishlist")) {
    getWishlistProducts(); // initial page loading
  }
}

const isLandscape = ref(false)
const isMobile = ref(false)

onMounted(() => {
  const checkDevice = () => {
    // Check if device is mobile using userAgent
    const isMobileDevice = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
    isLandscape.value = window.screen?.orientation?.type?.includes('landscape') || false
    isMobile.value = isMobileDevice
  }
  
  checkDevice()
  window.addEventListener('resize', checkDevice)
  window.addEventListener('orientationchange', checkDevice)

  refreshCart();
})

const nuxtApp = useNuxtApp();
const isLoading = ref<boolean>(false);

nuxtApp.hook("page:loading:start", () => {
  isLoading.value = true;
});

nuxtApp.hook("page:loading:end", () => {
  isLoading.value = false;
});
</script>

<template>
  <div class="wrap">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <LoadingOverlay :show="isLoading" />

    <div 
      class="fixed inset-0 z-[999] bg-black/90 items-center justify-center text-white text-center p-8 md:hidden"
      style="display: none;"
      :style="{
        display: isLandscape && isMobile ? 'flex' : 'none'
      }"
    >
      <div>
        <svg 
          class="w-16 h-16 mx-auto mb-4" 
          xmlns="http://www.w3.org/2000/svg" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            stroke-width="2" 
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" 
          />
        </svg>
        <p class="text-xl font-medium"> {{ $t('pleaseRotateDevice') }}</p>
        <p class="mt-2 text-gray-300"> {{ $t('bestViewedInPortraitMode') }}</p>
      </div>
    </div>
  </div>
</template>

<style></style>
