<script setup lang="ts">
import { ref } from "vue";
import {getCmsElementsFromCategory} from "~/server/customAPI";
const route = useRoute();

const layoutId = "01932a532a4a7fc7b7af361a48ce24b3"

let cmsElementsFromCategory = ref();

cmsElementsFromCategory = await getCmsElementsFromCategory(layoutId);

</script>
<template>
  <CmsPage v-if="cmsElementsFromCategory?.cmsPage" :content="cmsElementsFromCategory.cmsPage" />
</template>