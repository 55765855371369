<script setup lang="ts">
import {
  getCategoryRoute,
  getTranslatedProperty,
} from "@shopware/helpers";
import type { Schemas } from "#shopware";
const route = useRoute();
const props = defineProps<{
  navigationElement: NavigationElement;
  activeSubMenu: string | null;
}>();

const { navigationElement } = toRefs(props);

const currentActiveNavigationItem = ref<string | null>(null);
const currentActiveNavigationSubItem = ref<string | null>(null);
type NavigationElement = Schemas["Category"] & {
  activeClass?: boolean;
};

const isMenuActive = (child: NavigationElement, index: Number) => {
  return (
    currentActiveNavigationItem.value == child.id ||
    (index == 0 && !currentActiveNavigationItem.value)
  );
};

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

function toggleDropDown(id: string) {
  if (currentActiveNavigationSubItem.value == id) {
    currentActiveNavigationSubItem.value = null;
  } else {
    currentActiveNavigationSubItem.value = id;
  }
}

const isActive = (navigationElement: NavigationElement) => {
  const categoryRoute = formatLink(getCategoryRoute(navigationElement));
  if (
    categoryRoute &&
    typeof categoryRoute === "object" &&
    "path" in categoryRoute
  ) {
    return route.path === categoryRoute.path;
  }
  return false;
};
</script>
<template>
  <div
    class="menu mega-menu laptop:absolute w-full xl:w-[5000px] xl:ml-[-1915px] left-0 top-full bg-skin-white mt-0 pt-0 sm:pt-5 md:pt-0 xl:mt-[30px] md:pb-5 md:pl-10 desktop:pl-0 dekstop:pb-10 laptop:opacity-0 laptop:transition-all laptop:duration-300 laptop:pointer-events-none hidden laptop:!block"
    :style="{
      display: activeSubMenu == navigationElement.id ? 'block' : 'none',
    }"
    style="z-index: 10000"
  >
    <div class="container">
      <div class="flex -mx-5 min-w-full">
        <ul
          class="hidden md:block w-1/4 px-5 list-none prose-li:cursor-pointer prose-li:py-3 hover:prose-li:text-skin-theme [&>li.active]:text-skin-theme"
        >
          <template
            v-for="(child, i) in navigationElement.children"
            :key="child.id"
          >
            <li
              @mouseover="currentActiveNavigationItem = child.id"
              @focusin="currentActiveNavigationItem = child.id"
              :class="{
                active: isMenuActive(child, i),
              }"
            >
              <NuxtLink
                :to="formatLink(getCategoryRoute(child))"
                class="w-full block flex justify-between items-center"
                :class="{
                  'text-skin-theme': isMenuActive(child, i),
                }"
              >
                <span class="flex-1">{{
                  getTranslatedProperty(child, "name")
                }}</span>
                <span class="flex-none">
                  <i
                    v-if="child.children?.length"
                    class="fa-solid fa-angle-right text-xs"
                  ></i>
                </span>
              </NuxtLink>
            </li>
          </template>
        </ul>
        <div class="w-full md:w-3/4 px-5">
          <div
            v-for="(child, index) in navigationElement.children"
            class="block md:hidden fade px-5 pr-[5px] md:[&:not(.active)]:opacity-0 [&.active]:block transition-opacity duration-[0.15s] ease-linear md:border-l border-skin-primary md:h-full [&.active]"
            :class="{
              active: isMenuActive(child, index),
            }"
          >
            <div class="w-full flex">
              <NuxtLink
                :to="formatLink(getCategoryRoute(child))"
                class="w-full md:hidden text-left uppercase py-3.5 relative [&.active]:text-skin-theme block"
                :class="{
                  active:
                  currentActiveNavigationSubItem == child.id,
                }"
                @click="
                  (currentActiveNavigationItem = child.id), $emit('close-menu')
                "
              >
                {{ getTranslatedProperty(child, "name") }}
              </NuxtLink>
              <span
                class="pt-3 mx-2 cursor-pointer md:hidden"
                :class="{
                  'rotate-90 pt-4': currentActiveNavigationSubItem == child.id,
                }"
                @click="toggleDropDown(child.id)"
              >
                <i
                  v-if="child.children?.length"
                  class="fa-solid fa-angle-right text-xs"
                ></i>
              </span>
            </div>
            <ul
              class="prose-li:font-normal prose-li:text-skin-secondary prose-a:flex prose-a:items-center md:gap-y-5 md:!grid grid-cols-1 md:grid-cols-[50%_50%] desktop:grid-cols-[42%_30%] py-5 md:py-0 [&>li:not(:last-child)]:mb-4 md:[&>li]:!mb-0"
              :class="{
                hidden: currentActiveNavigationSubItem != child.id,
              }"
            >
              <li v-for="childNavigation in child.children">
                <NuxtLink
                  :to="formatLink(getCategoryRoute(childNavigation))"
                  :class="{
                    'text-skin-theme': isActive(childNavigation),
                  }"
                  @click="$emit('close-menu')"
                >
                  <span
                    class="inline-block min-w-[2.8rem] max-w-[2.8rem] h-[2.8rem]"
                  >
                    <NuxtImg
                      v-if="childNavigation.media?.url"
                      :src="childNavigation.media?.url"
                      :alt="getTranslatedProperty(childNavigation, 'name')"
                      loading="lazy"
                      :format="childNavigation.media?.fileExtension"
                      class="w-[2.8rem] h-[2.8rem]"
                    />
                    <img
                      v-else
                      src="@/assets/images/placeholder-product-nav.png"
                      alt="Placeholder image"
                      loading="lazy"
                      class="w-[2.8rem] h-[2.8rem]"
                    />
                  </span>
                  <span class="flex-1 pl-5">{{
                    getTranslatedProperty(childNavigation, "name")
                  }}</span>
                </NuxtLink>
                <LayoutMegaMenu
                  :navigationElement="childNavigation"
                  :activeSubMenu="activeSubMenu"
                ></LayoutMegaMenu>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>