import { defineNuxtPlugin } from "#app";
import { useHistoryNavigation } from "~/composables/useHistoryNavigation";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const { provideHistoryNavigation, historyState } = useHistoryNavigation();

    provideHistoryNavigation();

    nuxtApp.provide("historyState", historyState);

    if (typeof window !== "undefined") {
      sessionStorage.removeItem("popstateDetected");
    }

    nuxtApp.hook("app:mounted", () => {
      if (typeof window !== "undefined") {
        const currentPath = window.location.pathname + window.location.search;

        if (!sessionStorage.getItem("visitedRoutes")) {
          sessionStorage.setItem(
            "visitedRoutes",
            JSON.stringify([currentPath])
          );
        }

        historyState.isBackNavigation = false;

        const perfEntryType = window.performance
          ? (window.performance.getEntriesByType("navigation")[0] as any)?.type
          : "unknown";
      }
    });

    nuxtApp.hook("page:start", () => {
      const currentPath = window.location.pathname + window.location.search;
    });

    if (typeof window !== "undefined") {
      window.__historyDebug = {
        getState: () => historyState,
        getVisitedRoutes: () =>
          JSON.parse(sessionStorage.getItem("visitedRoutes") || "[]"),
      };
    }
  }
});
