import { useGtm } from "@gtm-support/vue-gtm"
import {getProductsById} from "~/server/customAPI";

const currency = "CHF"
const TagManager = useGtm();

function getItems(productData) {
    // Initialize an empty array to store the items
    const items = [];
    // Check if productData exists and is an array
    if (Array.isArray(productData)) {
        productData.forEach((product) => {
            const categoryIds = product.categoryIds || [];
            const categoryCount = categoryIds.length;

            // Create an object to store category information
            const categoryInfo = {};

            // Set item_category to the first category if it exists
            if (categoryCount > 0) {
                categoryInfo.item_category = categoryIds[0];
            }

            // Set additional categories as item_category1, item_category2, etc.
            for (let i = 1; i < categoryCount; i++) {
                categoryInfo[`item_category${i}`] = categoryIds[i];
            }

            items.push({
                item_name: product.translated.name,
                item_brand: product.manufacturer?.translated.name,
                item_id: product.productNumber,
                quantity: 1, // You can set the quantity to a default value
                price: product.calculatedPrice.totalPrice,
                ...categoryInfo, // Spread the categoryInfo object into the item
            });
        });
    }

    return items;
}

function getItem(productData, quantity = 1) {
    const item = [];
    const categoryIds = productData.categoryIds || [];
    const categoryCount = categoryIds.length;

    // Create an object to store category information
    const categoryInfo = {};

    // Set item_category to the first category if it exists
    if (categoryCount > 0) {
        categoryInfo.item_category = categoryIds[0];
    }

    // Set additional categories as item_category1, item_category2, etc.
    for (let i = 1; i < categoryCount; i++) {
        categoryInfo[`item_category${i}`] = categoryIds[i];
    }

    item.push({
        item_name: productData.translated.name,
        item_brand: productData.manufacturer?.translated.name,
        item_id: productData.productNumber,
        quantity: quantity, // You can set the quantity to a default value
        price: productData.calculatedPrice.totalPrice,
        ...categoryInfo, // Spread the categoryInfo object into the item
    });
    return item
}

function getCartItems(cartItems) {
    const itemsArray = [];
    if (Array.isArray(cartItems)) {
        cartItems.forEach((item) => {
            console.log(item);
            itemsArray.push({
                item_name: item.label,
                item_id: item.payload.productNumber,
                quantity: item.quantity, // You can set the quantity to a default value
                price: item.price.totalPrice,
            });
        });
    }
    return itemsArray
}

function pushDataLayer(dataLayer, TagManagerPlugin = undefined) {
    //@todo /pages section not able import GTM module {&& TagManager?.enabled() || TagManagerPlugin.enabled()}
    console.log('DATALAYER', dataLayer)
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push(dataLayer);
    }
}

function productListTracking(products){
    if (products?.length > 0) {
        const filteredItems = getItems(products);
        const tagManagerDataLayerArgs = {
            event: 'view_item_list',
            ecommerce: {
                currency: currency,
                items: filteredItems,
            },
        }
        pushDataLayer(tagManagerDataLayerArgs)
    }
}

function productDetailTracking(product) {
    if(Object.keys(product).length > 0) {
        const productItem = getItem(product)
        const tagManagerDataLayerArgs = {
            event: 'view_item',
            ecommerce: {
                currency: currency,
                items: productItem,
            },
        }
        pushDataLayer(tagManagerDataLayerArgs)
    }
}

function addToCartTracking(product, quantity) {
    if(Object.keys(product).length > 0) {
        const items = getItem(product, quantity)
        const tagManagerDataLayerArgs = {
            event: 'add_to_cart',
            ecommerce: {
                currency: currency,
                value: product.calculatedPrice.totalPrice * quantity,
                items: items,
            },
        }
        pushDataLayer(tagManagerDataLayerArgs)
    }
}

async function removeItemFromCart(product) {
    const searchResults = ref([]);
    searchResults.value = await getProductsById({
        "ids": product.referencedId
    });

    if(searchResults.value.elements.length > 0) {
        const tagManagerDataLayerArgs = {
            event: 'remove_from_cart',
            ecommerce: {
                currency: currency,
                items: searchResults.value.elements[0],
            },
        }
        pushDataLayer(tagManagerDataLayerArgs)
    }
}

function viewCartItems(product, total, shippingTotal, TagManagerPlugin) {
    if(product.value.length > 0) {
        const items = getCartItems(product.value)
        const tagManagerDataLayerArgs = {
            event: 'view_cart',
            ecommerce: {
                currency: currency,
                shipping: shippingTotal.value,
                tax: 0,
                value: total.value,
                items: items,
            },
        }
        pushDataLayer(tagManagerDataLayerArgs, TagManagerPlugin)
    }
}

function checkoutProcessTracking(products, total, event) {
    const items = getCartItems(products.value)
    const tagManagerDataLayerArgs = {
        event: 'begin_checkout',
        ecommerce: {
            currency: currency,
            total: total.value,
            CheckoutURL: window.location.href,
            items: items
        },
    }
    pushDataLayer(tagManagerDataLayerArgs)
}

function successTracking(orderData) {
    const orderDataFilter = orderData.value
    const orderItems = getLineItems(orderDataFilter?.lineItems)

    if(Object.keys(orderData.value).length > 0) {
        const tagManagerDataLayerArgs = {
            event: 'purchase',
            ecommerce: {
                currency: currency,
                transaction_id: orderDataFilter.orderNumber,
                value: orderDataFilter?.price.totalPrice,
                tax: orderDataFilter?.price.calculatedTaxes[0].tax,
                shipping: orderDataFilter?.shippingTotal,
                coupon: orderDataFilter?.campaignCode,
                items: orderItems,
            },
        }
        pushDataLayer(tagManagerDataLayerArgs);
    }
}

export function unpaidTracking(orderData) {
    const orderDataFilter = orderData.value
    const orderItems = getLineItems(orderDataFilter?.lineItems)

    if(Object.keys(orderData.value).length > 0) {
        const tagManagerDataLayerArgs = {
            event: 'unpaid',
            ecommerce: {
                currency: currency,
                transaction_id: orderDataFilter.orderNumber,
                value: orderDataFilter?.price.totalPrice,
                tax: orderDataFilter?.price.calculatedTaxes[0].tax,
                shipping: orderDataFilter?.shippingTotal,
                coupon: orderDataFilter?.campaignCode,
                items: orderItems,
            },
        }
        pushDataLayer(tagManagerDataLayerArgs);
    }
}

function getLineItems(orderItems) {
    const items = [];

    if (Array.isArray(orderItems)) {
        orderItems.forEach((item) => {

            items.push({
                item_name: item.label,
                item_id: item.payload.productNumber,
                quantity: item.quantity,
                price: item.totalPrice,
            });
        })
    }

    return items
}

function klaviyoIdentify(user) {
    if(window.klaviyo && user.value.email) {
        window.klaviyo.identify({
            '$email' : user.value.email,
            '$first_name' : user.value.firstName,
            '$last_name' :  user.value.lastName,
        });
    } else {
        console.log('No Klayvio available.');
    }
}

export {
    klaviyoIdentify,
    getItems,
    getItem,
    getCartItems,
    productListTracking,
    productDetailTracking,
    addToCartTracking,
    removeItemFromCart,
    viewCartItems,
    checkoutProcessTracking,
    successTracking,
}



/*
GA4 built-in events which can be configured in dataLayer
view_item - pdp
view_item_list - list
select_item -
add_to_cart
remove_from_cart
view_cart
begin_checkout
add_payment_info
add_shipping_info
purchase */